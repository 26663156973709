.form-box {
  border: 1px solid grey;
  padding: 50px;
}

.form-input {
  margin-top: 20px;
}

.form-heading {
  text-align: center;
  margin-top: 25px;
}

.submit-btn {
  width: 100%;
}

#reg-btn {
  float: right;
}

.reg-list-menu {
  width: 100%;
}

.reg-list-select {
  margin-left: 2%;
  color: black;
}

.reg-list-link {
  text-decoration: none;
}

.reg-list-link:hover {
  color: black;
  cursor: default;
}

/* Form validation styling */
input:invalid {
  border: 1px solid grey;
}

input:invalid:required {
  background-color: #e6eeff;
}

input:valid {
  border: 1px solid grey;
}

.error-alert {
  text-align: center;
}

.pass-eye {
  cursor: pointer;
  float: right;
  margin-top: -25px;
  margin-right: 10px;
}
