html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  position: relative;
  height: auto;
  min-height: 100%;
  padding-bottom: 200px;
  overflow-x: hidden;
}

.asterisk {
  color: #d0342c;
  font-size: 100%;
  font-weight: bold;
  margin-left: 3px;
}

.heroContainer {
  margin-bottom: 2%;
  width: 100%;
  min-height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  background-position: center center;
  background-color: #2c349a;
  overflow-x: hidden;
}

.heroHeading {
  text-align: center;
  margin-top: 3%;
  color: white;
  font-family: "Roboto", sans-serif;
  letter-spacing: 6px;
  font-size: 70px;
}

@media screen and (max-device-width: 480px) {
  .heroHeading{
    font-size: 2em;
    margin-top: 10%;
  }
}

.heroBody {
  border: 2px solid white;
  color: white;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
  margin-top: 1%;
  text-align: center;
  background-image: linear-gradient(rgb(0 0 0/65%) 0 0);
}

.heroText {
  line-height: 150%;
  font-weight: 400;
}

@media screen and (max-device-width: 480px) {
  .heroBody{
    margin-bottom: 10%;
  }
}

/* XS styling */
@media (max-width: @screen-xs-max) {
  .container {
    width: inherit;
  }
}

/* SM styling */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .container {
    width: inherit;
  }
}


