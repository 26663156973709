.prod-header {
  text-align: center;
}

.content-container {
  border: 1px solid grey;
  padding: 10px;
  margin-bottom: 25px;
}

.product-container {
  margin-top: 40px;
}

.row.product-content {
  margin-top: 75px;
  margin-bottom: 40px;
}

.nav-btn {
  width: 50%;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 10px;
}

.nav-btn:hover {
  border-color: #0039a6;
  color: #0039a6;
  font-weight: bold;
}

.resource-image {
  margin-right: 25px;
}
