/* ***** Home Jumbotron ***** */

.home-background {
  background-image: url("./home-background-1.jpg");
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 9%;
  background-position: center center;
  background-color: #0b355f;
}

#home-dropdown {
  width: 100%;
  border-radius: 12px;
  font-size: 16px;
}

#slogan-all {
  border-left: 2px solid white;
  text-shadow: 0 0 200px #000000;
}

#slogan-row {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  margin-top: 4%;
  margin-left: 15px;
  font-weight: 600;
}

@media screen and (max-device-width: 480px) {
  #slogan-row{
    font-size: 2em;
  }
}

#sub-slogan-row {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  margin-top: 5%;
  margin-bottom: 8%;
  margin-left: 15px;
}

#home-arrow {
  color: white;
  margin-left: 50%;
}

/* ***** STANDARD PRODUCT SECTION ***** */

#products-row {
  margin-bottom: 3%;
}

@media screen and (max-device-width: 480px) {
  #products-row{
    margin-bottom: 9%;
  }
}

.products-intro {
  color: #061e45;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 1%;
}

.home-products-link {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.product-content-home {
  margin-top: auto;
  margin-bottom: auto;
}

#product-top-left {
  background-image: url("./product-background-blue.jpeg");
  background-size: cover;
  color: white;
  text-align: center;
  border: none;
  border-radius: 0px;
  border-top-left-radius: 70px 70px;
  height: 100%;
}

#product-top-right {
  background-image: url("./product-background-gray.jpeg");
  background-size: cover;
  color: #061e45;
  text-align: center;
  border-radius: 0px;
  border: none;
  padding: 3%;
}

#product-middle-left { 
  text-align: center;
  color: #061e45;
  padding: 3%;
  border-radius: 0px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  height: 100%;
}

#product-middle-right { 
  background-image: url("./product-background-blue.jpeg");
  background-size: cover;
  text-align: center;
  color: white;
  padding-top: 3%;
  padding-bottom: 2%;
  border-radius: 0px;
  border: none;
  height: 100%;
}

#product-bottom-left {
  background-image: url("./product-background-blue.jpeg");
  background-size: cover;
  color: white;
  text-align: center;
  border: none;
  border-radius: 0px;
  height: 100%;
}

#product-bottom-right {
  background-image: url("./product-background-gray.jpeg");
  background-size: cover;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  border: none;
  border-bottom-right-radius: 70px 70px;
  padding-bottom: 3%;
}

.card.h-65 {
  padding-top: 25%;
  height: 75%;
  color: #061e45;
  border-radius: 20px;
}

.triple-box {
  margin-bottom: -25%;
  z-index: 999999;
  position: relative;
}

/* ***** CUSTOM SECTION ***** */

#custom-link {
  text-decoration: none;
  color: white;
}

#custom-background {
  text-align: center;
  background-image: url("custom-background.jpeg");
  background-size: cover;
  padding-bottom: 4%;
  margin-bottom: -4%;
}

@media screen and (max-device-width: 480px) {
  #custom-background{
    margin-bottom: -15%;
  }
}

#custom-header {
  margin-top: 3%;
}

#custom-list {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2%;
  text-align: left;
}

.custom-bullets {
  color: white;
}

/* OLD PRODUCT SECTION DESIGN */

/* .product-card-0, .product-card-7 {
  background-color: #57a7c9;
  color: white;
  border: none; 
  padding: 2%;
  color: #061e45;
} */

/* .product-card-1, .product-card-6 {
  background-color: #216e9e;
  color: white;
  border: none; 
  padding: 2%;
  color: #061e45;
} */

/* .product-card-2, .product-card-5 {
  background-color: #0d2e60;
  color: white;
  border: none; 
  padding: 2%;
  color: #061e45;
} */

/* .product-card-3, .product-card-4 {
  background-color: #020636;
  color: white;
  border: none; 
  padding: 2%;
  color: #061e45;
} */


/* #product-background {
  background-image: url("./home-background-2B.jpeg");
  background-size: cover;
  background-position: center center;
  padding-bottom: 3%;
} */

/* .products-intro {
  color: white;
  text-align: center;
  margin-bottom: 3%;
} */

/* #products-back-row {
  margin-top: -3%;
} */

/* .product-link {
  text-decoration: none;
  color: #061e45;
  text-align: center;
} */

/* .product-link:hover {
  color: #d0d0d0;
} */

/* .bgOpacity {
  background: rgba(0, 0, 0, 0);
  opacity: 1;
  filter: grayscale(85%);
  border: none;
}

.bgOpacity:hover {
  filter: grayscale(0%);
} */

/* .carousel-button {
  width: 75%;
  margin-top: 10%;
} */
