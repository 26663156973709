.hti-footer {
  color: white;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 150px;
}

.socialMedia {
  margin-left: 5%;
  color: white;
}





